.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;

}

.App a {
    background: #30a03a;
    width: 85%;
    max-width: 400px;
    padding: 10px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    border-radius: 50px;
    margin-bottom: 20px;
}
